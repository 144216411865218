<template>
    <div class="rs-introduce-container">
        <div class="content">
            <div class="card">
                <div class="title-2">一、参与范围</div>
                <div class="p">重庆市中小学生</div>
                <div class="title-2">二、投稿时间</div>
                <div class="p">2024年6月20日至2025年03月09日</div>
                <div class="title-2">三、作品要求</div>
                <div class="title-3">（一）内容要求</div>
                <div class="p">
                    围绕党史、新中国史、改革开放史、社会主义发展史、中华民族发展史和红岩革命故事等主题，自行组织“红色故事发声人”短视频、征文展示内容，符合历史事实、符合主流价值、内容积极向上。
                </div>
                <div class="title-3">（二）质量要求</div>
                <div class="p">
                    1.短视频：横屏拍摄，拍摄画面稳定，声音清晰，画质力求精美；格式为MOV或MP4，画面分辨率为1080P，大小不超过100M，时长不超过5分钟。
                </div>
                <div class="p">
                    2.征文：自选稿纸手写征文，字迹工整；扫描或高清拍照上传jpg格式，画面清晰；小学生作品不低于200字，初中生不低于400字，高中生不低于600字。
                </div>
                <div class="title-3">（三）版权要求</div>
                <div class="p">
                    须为原创作品，突出创意性，拥有独立完整的著作权，凡涉及著作权、肖像权、名誉权、隐私权等在内的任何法律责任均由参与者本人承担，活动作品知识产权归活动主办单位、承办单位和参加展示者共同所有，作品可无偿用于公益事业宣传。
                </div>
                <div class="title-2">四、作品上传</div>
                <div class="p">
                    第一步：下载并安装光明教育家APP（方式一：扫描下载；方式二：安卓用户通过应用市场，ios用户通过App
                    Store直接搜索“光明教育家”下载）
                </div>
                <img src="../../../../public/static/images/activity/red-story/QRCode.png" alt="" />
                <div class="p">
                    第二步：打开光明教育家APP，新用户需使用手机验证码登录并选择身份，完成注册；老用户直接登录
                </div>
                <img style="width: 50%" src="../../../../public/static/images/activity/red-story/tip1.png" alt="" />
                <img style="width: 50%" src="../../../../public/static/images/activity/red-story/tip2.png" alt="" />
                <div class="p">
                    第三步：打开光明教育家APP首页—精彩推荐—点击轮播图—进入“红色故事发声人”专题—点击作品上传—按照提示进行操作上传（每个用户同一个作品只能上传一次，勿重复提交）
                </div>
                <img style="width: 50%" src="../../../../public/static/images/activity/red-story/tip3.png" alt="" />
                <div class="p">第四步：作品上传成功后，会看到如下文本框提示。</div>
                <img src="../../../../public/static/images/activity/red-story/tip4.png" alt="" />
                <div class="title-2">五、作品审核</div>
                <div class="p">
                    作品上传成功后，主办方会进行作品内容和格式审核。审核结果将会通过系统发送短信至注册时绑定手机号上。
                </div>
                <div class="title-2">六、作品展示</div>
                <div class="p">
                    审核成功的作品，将会进行专题展示，点开光明教育家APP首页—精彩推荐——点击轮播图——进入“红色故事发声人”专题即可观看。
                </div>
                <div class="title-2">七、技术咨询</div>
                <div class="p">
                    如果在上传过程中，遇到不清楚问题，请拨打咨询电话曾老师
                    <a href="tel://13609420830">13609420830</a>
                    ，蔡老师。
                    <a href="tel://18580623231">18580623231 </a>。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AuthMixin } from "@/assets/utils/mixin"
import Clipboard from "clipboard"
import { Notify } from "vant"
export default {
    mixins: [AuthMixin],
    name: "RSIntroduce",
    data() {
        return {}
    },
    created() {
        const clipboard = new Clipboard("#downloadUrl")
        clipboard.on("success", () => {
            Notify({ type: "success", message: "地址复制成功" })
        })
    },
    mounted() {
        this.initWxConfig({
            itemName: "“红色故事发声人”活动介绍",
            desc: "“红色故事发声人”活动介绍",
            pic: "https://image2.cqcb.com/d/file/image/default/2021-05-17/7f0a5358236913593b031b3fdbdd31f8.jpeg",
            link: `activity/read-story-2024/introduce`,
        })
    },
    methods: {
        downloadTemplate() {
            window.location.href = "http://47.99.57.208:9528/template.xlsx"
        },
    },
}
</script>

<style lang="less" scoped>
.rs-introduce-container {
    .content {
        margin-top: -4px;
        background-color: #e41926;
        padding: 20px;

        .card {
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            .title {
                width: 100%;
                text-align: center;
                font-size: 28px;
                line-height: 48px;
                font-weight: bold;
            }
            .title-2 {
                margin: 5px 0 0 0;
                font-size: 20px;
                line-height: 48px;
                font-weight: bold;
            }
            .title-3 {
                font-size: 18px;
                line-height: 48px;
                font-weight: bold;
                text-indent: 2em;
            }
            .p {
                font-size: 18px;
                line-height: 32px;
                text-indent: 2em;
                text-align: justify;
            }
        }
    }
}
</style>
