<template>
    <div class="trends-detail-container">
        <button class="back-button" @click="handleBack">
            <img src="../../../../public/static/images/activity/red-story/back_icon.png" alt="" />
            <span>返回上一级</span>
        </button>
        <div class="card">
            <div class="title-wrapper">
                <h1 class="title">{{ info.title }}</h1>
            </div>
            <div v-if="info.link" style="margin-top: 0; position: relative">
                    <video
                        width="670"
                        height="376"
                        :src="info.link"
                        controls
                        controlslist="nodownload"
                    />
                </div>
            <div class="ql-snow ">
                <div class="ql-editor" v-html="info.properties" />
            </div>
        </div>
    </div>
</template>

<script>
import "/src/assets/css/quill.css";

import { getReadStoryTrendsDetail } from "@/assets/api/api_activity"

export default {
    name: "RSTrendsDetail",
    components: {},
    data() {
        return {
            id: this.$route.query.id,
            info: {},
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getInfo()
    },
    methods: {
        // 返回
        handleBack() {
            this.$router.push({ path: "/activity/carnival/trends" })
        },
        async getInfo() {
            const res = await getReadStoryTrendsDetail(this.id)
            this.info = res.data
        },
    },
}
</script>
<style lang="less">
.ql-editor{
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    img {
        width: revert-layer;
    }
}
</style>
<style lang="less" scoped>
.ql-snow{
    width: 100%;
}
.trends-detail-container {
    margin-top: -4px;
    background-color: #56b0fe;
    padding: 20px;
    .back-button {
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            img {
                width: 40px;
                height: 40px;
            }

            span {
                margin-left: 8px;
                color: white;
                font-size: 28px;
            }
        }

    .card {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title-wrapper {
        margin-top: 40px;

        .title {
            font-weight: bold;
            font-size: 32px;
            color: #333333;
            line-height: 44px;
        }
    }

    .content {
        margin-top: 16px;
        width: 100%;
        font-size: 28px;
        line-height: 44px;
        color: #333333;
    }
}
</style>
