<template>
    <div class="rs-index-container">
        <img class="banner" src="../../../../public/static/images/activity/carnival/top.jpg" alt="" />
        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path"></component>
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive"></component>
        </router-view>
        <van-tabbar active-color="#fff" class="bg-current" :safe-area-inset-bottom="false" inactive-color="#aaa" route :border="false">
            <van-tabbar-item class="text-transparent" replace to="/activity/carnival/display">作品展示</van-tabbar-item>
            <van-tabbar-item class="text-transparent" replace to="/activity/carnival/enroll">报名通道</van-tabbar-item>
            <van-tabbar-item class="text-transparent"  replace to="/activity/carnival/introduce">活动介绍</van-tabbar-item>
            <van-tabbar-item class="text-transparent" replace to="/activity/carnival/trends">活动动态</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    // "光明小记者嘉年华"
    name: "carnivalIndex",
    data() {
        return {}
    },
}
</script>

<style lang="less" scoped>
.banner {
    width: 100%;
}
.rs-index-container {
    box-sizing: border-box;
    padding-bottom: var(--van-tabbar-height);
}
@supports (-webkit-touch-callout: none) {
    .rs-index-container {
        margin-bottom: 40px;
    }
}
.text-transparent {
    font-weight: bold;
}

.van-tabbar {
    background-color: #fff;
}

@supports (-webkit-touch-callout: none) {
    .bg-current {
        padding-bottom: 40px;
    }
}
.van-tabbar-item--active {
    background-color: #56b0fe;
}
</style>
