<template>
    <div class="trends-item-container">
        <div><img class="cover" :src="item.cover" alt="" /></div>
        <div class="titleWrapper">
            <span class="title">{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "trends-item",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
    },
}
</script>
<style lang="less" scoped>
.trends-item-container {
    display: flex;
    flex-direction: row;
    padding-block: 20px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;

    .cover {
        width: 256px;
        height: 180px;
        object-fit: fill;
    }

    .titleWrapper {
        margin-left: 20px;
        flex: 1;

        .title {
            color: #333333;
            font-size: 28px;
            line-height: 40px;
        }
    }
}
</style>
