<template>
    <div class="notice-container">
        <div class="card">
            <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoadMore">
                <div class="list">
                    <TrendsItem
                        v-for="item in list"
                        :key="item"
                        :item="item"
                        @click="$router.push({ name: 'carnivalTrendsDetail', query: { id: item.id } })"
                    />
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { getReadStoryTrendsList } from "@/assets/api/api_activity"
import TrendsItem from "./components/trendsItem"
export default {
    name: "RSTrends",
    components: { TrendsItem },
    data() {
        return {
            queryParams: { start: 0, length: 10 },
            hasMroe: true,
            list: [],
            loading: false,
            finished: false,
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 获取数据
        async getList() {
            if (!this.hasMroe) {
                return
            }
            const params = Object.assign({periodId: 3}, this.queryParams)
            const res = await getReadStoryTrendsList(params)
            this.loading = false
            if (res.errcode !== 0) {
                Notify({ type: "danger", message: res.errmsg })
                return
            }
            if (params.start === 0) {
                this.list = res.data?.rows || []
            } else {
                this.list = this.list.concat(res.data?.rows || [])
            }
            this.finished = this.list.length >= res.data?.count || 0
            this.hasMroe = res.data?.count || 0 > this.list.length
            this.total = res.data?.count || 0
            this.queryParams.start += 10
        },
        // 上拉加载更多
        onLoadMore() {
            this.getList()
        },
    },
}
</script>
<style lang="less" scoped>
.notice-container {
    margin-top: -4px;
    background-color: #56b0fe;
    padding: 20px;

    .card {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        padding-top: 0;

        .list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
    }
}
</style>
